import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        const urls = JSON.parse(this.element.dataset.urls);
        const menus = this.element.querySelectorAll('[data-menu-country]');

        for (const [key, value] of Object.entries(urls)) {
            const path = this.element.querySelector('#'+key+'_plain');
            const hover = this.element.querySelector('#'+key+'_hover');
            const menu = this.element.querySelector('[data-menu-country="'+key+'"]');

            if (path) {
                path.classList.add('can-click');
                path.addEventListener('click', () => {
                    if (hover && menu) {
                        menus.forEach((element) => {
                            element.classList.remove('show-menu');
                        });

                        menu.classList.add('show-menu');
                    } else {
                        if (value.fullsize) {
                            window.open(value.url, '_blank');
                        } else {
                            window.location = value.url;
                        }
                    }

                    if (hover) {
                        path.classList.add('show-hover');
                    }
                });

                if (hover) {
                    path.addEventListener('mouseenter', () => {
                        path.classList.add('show-hover');
                    });

                    path.addEventListener('mouseleave', () => {
                        path.classList.remove('show-hover');
                    });
                }
            }
        }
    }
}
