import { Controller } from '@hotwired/stimulus'
import { debounce } from '../scripts/debounce'

export default class extends Controller {
    static targets = ['price', 'total', 'subtotal', 'form'];

    connect() {
        this.update = false;
    }

    increment(event) {
        const button = event.target;
        const input = event.target.closest('tr').querySelector('input');

        if (null === input) {
            return;
        }

        input.value = Math.max(0, parseFloat(input.value) + parseFloat(button.dataset.increment));
        input.dispatchEvent(new Event('change'));
    }

    debounceCalculate = debounce(() => { this.update = true; this.formTarget.requestSubmit() }, 750)
    
    calculate(event) {
        this.update = false;
        let total = 0;

        if ('INPUT' === event.target.tagName) {
            let value = event.target.value;

            if ('' === value) {
                event.target.value = 0;
            }
        }

        const priceFormat = new Intl.NumberFormat('de-AT', {
            style: 'currency',
            currency: 'EUR'
        });

        this.priceTargets.forEach((price) => {
            const input = price.closest('tr').querySelector('input');
            const itemTotal = (parseFloat(price.dataset.price) * Math.max(0, parseFloat(input.value)));

            price.innerText = priceFormat.format(itemTotal);

            if (null !== input) {
                total += (parseFloat(price.dataset.price) * Math.max(0, parseFloat(input.value)));
            }
        });

        this.totalTarget.innerText = priceFormat.format(total);

        if (this.hasSubtotalTarget) {
            this.subtotalTarget.innerText = priceFormat.format(total);
        }

        if (this.hasFormTarget) {
            this.debounceCalculate();
        }
    }

    formdata(e) {
        if (!this.update) {
            return;
        }

        const formData = e.formData;

        if (formData.get('button_checkout')) {
            return;
        }

        formData.set('button_update', 1);
    }
}
