import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['input'];

    connect() {
        document.documentElement.addEventListener('click', (e) => {
            if (!this.element.contains(e.target)) {
                this.close();
            }
        })
    }

    toggleOrExecute() {
        if (document.body.classList.contains('show-search')) {
            if (!!this.inputTarget.value) {
                this.element.requestSubmit();
            } else {
                this.close();
            }
        } else {
            document.body.classList.add('show-search');
            setTimeout(() => {
                this.inputTarget.focus();
            }, 200);
        }
    }

    close() {
        document.body.classList.remove('show-search');
        this.inputTarget.value = '';
    }
}
