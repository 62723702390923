import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
    connect() {
        const lightbox = GLightbox({
            selector: 'a[data-nig25-lightbox]',
            autoplayVideos: true,
            autofocusVideos: true,
            videosWidth: '1440px'
        });

        lightbox.on('open', () => {
            lightbox.modal.classList.add('glightbox-nig25list');
        });
    }
}
