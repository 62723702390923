import '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import './scripts/glightbox';
import './css/styles.scss';

// Start Stimulus application
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context));
application.debug = process.env.NODE_ENV === 'development';

// Automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

// Always break out of a missing frame (#7501)
document.documentElement.addEventListener('turbo:frame-missing', (e) => {
    if (window.console) {
        console.warn('Turbo frame #'+e.target.id+' is missing.');
    }

    // Do not break out of frames that load their content via src
    if (e.target.hasAttribute('src')) {
        return;
    }

    e.preventDefault();
    e.detail.visit(e.detail.response);
});
