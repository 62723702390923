import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['form'];

    open() {
        this.formTarget.classList.add('open');
        this.formTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
}
