import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['float'];

    connect() {
        this.element.classList.add('show');
    }

    toggle(e) {
        this.floatTargets.forEach((float) => {
            if (float.contains(e.currentTarget)) {
                float.classList.toggle('show');
            }
        });
    }
}
