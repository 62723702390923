import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        document.documentElement.addEventListener('click', (e) => {
            if (!this.element.contains(e.target)) {
                this.close();
            }
        })
    }

    toggle(e) {
        this.element.classList.toggle('show');
    }

    close() {
        this.element.classList.remove('show');   
    }
}
