import { Controller } from '@hotwired/stimulus'

const stickyThreshold = 320;
const threshold2 = 500;

export default class extends Controller {
    connect() {
        const body = document.body;
        let lastScrollPos = null;
        let timeout = null;

        (function checkScrollPos() {
            if (window.scrollY > 0) {
                body.classList.add('scroll--scrolled');
            } else {
                body.classList.remove('scroll--scrolled');
            }

            if (window.scrollY >= stickyThreshold) {
                body.classList.add('scroll--sticky');
            } else {
                body.classList.remove('scroll--sticky');
            }

            if (window.scrollY >= threshold2) {
                body.classList.add('scroll--th2');
            } else {
                body.classList.remove('scroll--th2');
            }

            if (null !== lastScrollPos && lastScrollPos !== window.scrollY) {
                const delta = window.scrollY - lastScrollPos;

                body.classList.add('scroll--moving');
                body.classList.remove('scroll--stop');
                body.classList.remove('scroll--down');
                body.classList.remove('scroll--up');

                if (delta >= 0) {
                    body.classList.add('scroll--down');
                } else {
                    body.classList.add('scroll--up');
                }

                clearTimeout(timeout);
        
                timeout = setTimeout(() => {
                    body.classList.remove('scroll--moving');
                    body.classList.add('scroll--stop');
                }, 500);
            }

            if (null !== lastScrollPos || window.scrollY > 0) {
                lastScrollPos = window.scrollY;
            }
    
            window.requestAnimationFrame(checkScrollPos);
        })();
    }
}
