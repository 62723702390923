import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['groupedWidget'];

    toggle(e) {
        e.currentTarget.closest('.widget').classList.toggle('open');
    }

    connect() {
        document.addEventListener('click', (e) => {
            this.groupedWidgetTargets.forEach((widget) => {
                if (!widget.contains(e.target)) {
                    widget.classList.remove('open');
                }
            });
        });
    }

    toggleChildren(e) {
        e.currentTarget.closest('.widget-grouped-checkboxes__checkbox').classList.toggle('widget-grouped-checkboxes__checkbox--open');
    }

    toggleCheckbox(e) {
        if (e.currentTarget.checked) {
            e.currentTarget.closest('.widget-grouped-checkboxes__checkbox').classList.add('widget-grouped-checkboxes__checkbox--open');
        }
    }
}
