import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
    connect() {
        const storageId = this.element.dataset.storageId;

        if (null !== localStorage.getItem(this.element.dataset.storageId)) {
            return;
        }

        const lightbox = GLightbox({
            autoplayVideos: true,
            autofocusVideos: true,
            touchNavigation: false,
            videosWidth: '1280px',
            elements: [
                {
                    href: this.element.dataset.video,
                    type: 'video',
                    source: 'local'
                }
            ]
        });

        lightbox.on('open', () => {
            lightbox.nextButton.style.display = 'none';
            lightbox.prevButton.style.display = 'none';
        });

        lightbox.on('close', () => {
            localStorage.setItem(storageId, 1);
        });

        lightbox.open();
    }
}
