import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['input'];

    select(e) {
        this.element.classList.add('show-submit');

        this.inputTargets.forEach((input) => {
            input.classList.remove('radio-active');
        });

        e.currentTarget.classList.add('radio-active');
    }

    toggleNote(e) {
        e.currentTarget.classList.toggle('note-open');
    }
}
