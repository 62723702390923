import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['button', 'wrapper'];

    wrapperTargetConnected(wrapper) {
        wrapper.style.display = 'none';

        if (this.getSelectedLanguage() === wrapper.lang) {
            wrapper.style.display = 'block';
        }
    }

    buttonTargetConnected(button) {
        button.classList.remove('active');

        if (this.getSelectedLanguage() === button.dataset.language) {
            button.classList.add('active');
        }
    }

    getSelectedLanguage() {
        const selectedLanguage = localStorage.getItem('language-wrapper-selection');

        if (null === selectedLanguage) {
            return this.element.dataset.defaultLanguage;
        }

        return selectedLanguage;
    }

    select(e) {
        const button = e.currentTarget;
        const language = button.dataset.language;

        localStorage.setItem('language-wrapper-selection', language);

        this.wrapperTargets.forEach((wrapper) => {
            wrapper.style.display = 'none';

            if (language === wrapper.lang) {
                wrapper.style.display = 'block';
            }
        });

        this.buttonTargets.forEach((button) => {
            button.classList.remove('active');

            if (language === button.dataset.language) {
                button.classList.add('active');
            }
        });
    }
}
