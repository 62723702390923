import GLightbox from 'glightbox';

window.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('a[data-lightbox]').forEach((element) => {
        if (!!element.dataset.lightbox) {
            element.setAttribute('data-gallery', element.dataset.lightbox);
        } else {
            element.setAttribute('data-gallery', crypto.randomUUID());
        }
    });

    GLightbox({
        selector: 'a[data-lightbox]'
    });
});
