import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        const autoresize = (textarea) => {
            textarea.style.minHeight = '0px';
            textarea.style.minHeight = (textarea.scrollHeight + 1) + 'px';
        };

        autoresize(this.element);

        this.element.addEventListener('input', () => {
            autoresize(this.element);
        });
    }
}
