import { Controller } from '@hotwired/stimulus'
import { debounce } from '../scripts/debounce'

export default class extends Controller {
    static targets = ['onchange', 'onkeydown']

    onchangeTargetConnected(input) {
        input.addEventListener('change', () => {
            input.form.requestSubmit();
        });
    }

    onkeydownTargetConnected(input) {
        const debounceKeydown = debounce(() => { input.form.requestSubmit() }, 300)

        input.addEventListener('keydown', (event) => {
            if ('Tab' === event.key) {
                return;
            }

            debounceKeydown();
        });
    }
}
