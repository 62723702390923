import { Controller } from '@hotwired/stimulus'
import Macy from 'macy'

export default class extends Controller {
    static targets = ['articles'];
    macys = new WeakMap();
    limit = 9;
    offset = 0;

    updateMacy(container) {
        if (!this.macys.has(container)) {
            return;
        }

        const macy = this.macys.get(container);

        macy.recalculate();
        macy.runOnImageLoad(() => {
            macy.recalculate(true);
        }, true);
    }

    initMacy(container, columns) {
        if (this.macys.has(container)) {
            return;
        }

        const macy = new Macy({
            container: container,
            trueOrder: true,
            mobileFirst: true,
            columns: 1,
            margin: 32,
            breakAt: {
                600: {
                    columns: 2
                },
                900: {
                    columns: columns
                }
            }
        });

        macy.runOnImageLoad(() => {
            macy.recalculate(true);
        }, true);

        this.macys.set(container, macy);
    };

    articlesTargetConnected(container) {
        let columns = 0;

        if (this.element.classList.contains('two-columns')) {
            columns = 2;
        }

        if (this.element.classList.contains('three-columns')) {
            columns = 3;
        }

        if (this.element.classList.contains('related-news')) {
            columns = 3;
        }

        if (this.element.classList.contains('four-columns')) {
            columns = 4;
        }

        if (0 === columns) {
            return;
        }

        this.initMacy(container, columns);
    }

    articlesTargetDisconnected(container) {
        if (!this.macys.has(container)) {
            return;
        }

        const macy = this.macys.get(container);
        macy.remove();
    }

    revealNextItems = function(container) {
        const items = container.children;

        Array.from(items).slice(this.offset, this.offset + this.limit).forEach((element) => {
            element.style.display = 'block';
        });

        this.updateMacy(container);
        this.offset += this.limit;
    };

    showCombinedNewsTeasers(e) {
        e.currentTarget.closest('.news-teaser').querySelectorAll('.combined-teasers').forEach((teasers) => {
            teasers.classList.remove('combined-teasers--invisible');
        });

        e.currentTarget.style.display = 'none';

        this.articlesTargets.forEach((container) => {
            if (container.contains(e.currentTarget)) {
                this.updateMacy(container);
            }
        });
    };

    connect() {
        if (this.element.classList.contains('--fake-load-more')) {
            const newslist = this.element;
            const articles = this.articlesTarget;
            const items = articles.children;

            if (items.length > this.limit) {
                Array.from(items).forEach((element) => {
                    element.style.display = 'none';
                });

                this.revealNextItems(articles);

                let buttonContainer = document.createElement('div');
                buttonContainer.classList.add('newslist__load-more-container');

                let button = document.createElement('div');
                button.classList.add('newslist__load-more');
                button.innerText = 'Mehr laden';

                buttonContainer.appendChild(button);
                newslist.appendChild(buttonContainer);

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.revealNextItems(articles);

                    if (items.length < this.offset) {
                        button.style.display = 'none';
                    }

                    return false;
                });
            }
        }
    }
}
