import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['ignore'];

    outside(event) {
        const isIgnored = (element) => {
            return 0 === this.ignoreTargets.filter((ignore) => {
                return ignore.contains(element);
            });
        };

        const target = event.target;

        if (target !== this.element && !this.element.contains(target) && !isIgnored(target)) {
            this.close();
        }
    }

    toggle() {
        document.body.classList.toggle('show-menu');
    }

    close() {
        document.body.classList.remove('show-menu');
    }
}
