import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['level'];

    connect() {
        document.documentElement.addEventListener('click', (e) => {
            if (!this.element.contains(e.target)) {
                this.close();
            }
        })
    }

    open(e) {
        const item = e.currentTarget.parentNode;
        const isOpen = item.classList.contains('open');

        [].slice.call(item.parentNode.children).forEach(element => {
            element.classList.remove('open')
        });

        if (isOpen) {
            item.classList.remove('open')
        } else {
            item.classList.add('open')
        }
    }

    toggle(e) {
        const item = e.currentTarget.parentNode;
        item.classList.toggle('open')
    }

    close() {
        this.levelTargets.forEach((level) => {
            if (level.classList.contains('level_1')) {
                Array.from(level.children).forEach((item) => {
                    item.classList.remove('open');
                });
            }
        });
    }
}
